import React, { Component } from 'react';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { Realm } from '@amzn/limestone-experiment-portal-types';
import { CdcApiHandler } from '../../api/data-collection/handler/cdc-api-handler';
import { NonCancelableCustomEvent, FormField, SpaceBetween, Box, Multiselect, MultiselectProps, Container } from '@amzn/awsui-components-react-v3';
import { AdditionalCustomMetricsField } from '../../form/attributes';
import { DisplayMode, LimestoneExperiment, UserAttributes } from '@amzn/limestone-experiment-portal-types';
import { CustomMetricsSubSection } from './CustomMetricSubSection';

export interface CustomMetricsSectionProps {
    realm: Realm;
    experiment: LimestoneExperiment;
    setNotification: Function;
    isWriteActionEnabledForUser: boolean;
    userAttributes: UserAttributes;
}

export interface CustomMetricsSectionState {
    experiment: LimestoneExperiment;
    showSpinner: boolean;
    selectedOptions: ReadonlyArray<MultiselectProps.Option>;
}

export class CustomMetricsSection extends Component<CustomMetricsSectionProps, CustomMetricsSectionState> {
    public dataCollectionAPI: CdcApiHandler;

    constructor(props: CustomMetricsSectionProps) {
        super(props);
        this.state = {
            experiment: props.experiment,
            showSpinner: false,
            selectedOptions: [],
        };

        this.dataCollectionAPI = new CdcApiHandlerImpl(props.realm);
    }

    onCustomMetricOptionSelectionChange = async(event: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail>) => {
        await this.setState({ selectedOptions: event.detail.selectedOptions });
    }

    render() {
        const customMetricRequestForm = (
            <div style={{ margin: '10px 0 10px 0' }}>
                <SpaceBetween size="xl">
                    <Box variant="h4" padding="n">Select Custom Metric</Box>
                    <FormField data-testid={'additional-custom-metrics-section'}>
                        <AdditionalCustomMetricsField
                            data-testid='additional-custom-metrics-dropdown'
                            displayMode={DisplayMode.VIEW}
                            experimentId={this.state.experiment.experimentId}
                            realm={this.props.realm}
                            initialValue={this.state.experiment.metadata.customMetrics.payloadValue}
                            isAuthorizedToEdit={this.props.isWriteActionEnabledForUser}
                        />
                    </FormField>
                    <FormField data-testid={'select-custom-metrics-section'}>
                        {this.state.experiment.metadata.customMetrics.payloadValue.length > 0 && <Multiselect
                            data-testid={'date-change-dropdown'}
                            options={this.state.experiment.metadata.customMetrics.payloadValue.map((customMetric: any) => { return { label: customMetric.displayName, value: customMetric.metricType };})}
                            selectedOptions={this.state.selectedOptions}
                            onChange={this.onCustomMetricOptionSelectionChange}
                        />}
                    </FormField>
                </SpaceBetween>
            </div>
        );

        return (
            <Container>
                {customMetricRequestForm}
                {this.state.selectedOptions.length > 0 && <CustomMetricsSubSection
                    realm={this.props.realm}
                    experiment={this.props.experiment}
                    setNotification={this.props.setNotification}
                    metricTypes={this.state.selectedOptions.map((selectedOption) => selectedOption.value!)}
                    userAttributes={this.props.userAttributes}
                />}
            </Container>
        );
    }
};

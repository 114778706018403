import React, { Component } from 'react';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ListDisplay, ListDisplayItem } from '../../common/ListDisplay';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { getActionRequiredNotification, IProps } from '@amzn/limestone-experiment-portal-types';
import {
    ExperimentOfferDto,
    GlMap,
    PreExperimentSelectionAnalysisResultDto,
    SelectionAnalysisWarningDto,
    SelectionAnalysisWarningType,
} from '@amzn/limestone-experiment-portal-types';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { BaseEntityType, LifecycleType, Realm } from '@amzn/limestone-experiment-portal-types';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import AwsServicesApiHandlerImpl from '../../api/aws-services/aws-services-handler-impl';
import { AwsServicesApiHandler } from '../../api/aws-services/aws-services-handler';
import { ActionType, PermissionsMap, UserAccessLevel } from '@amzn/limestone-experiment-portal-types';
import { PermissionControlledButton } from '../../permissions/PermissionControlledButton';
import { Box, ExpandableSection, ProgressBar, SpaceBetween, Spinner } from '@amzn/awsui-components-react-v3';
import { OfferRegionalizationStatusType, OfferValidationStatusType } from '@amzn/limestone-experiment-portal-types';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import { UserInputModal } from '../../common/UserInputModal';
import { DisplayAttribute, FinalizeSelectionModalAttributes } from '@amzn/limestone-experiment-portal-types';
import { PermissionControlledView } from '../../permissions/PermissionControlledView';
import { ActionRequiredMessages, AttributeLabels } from '@amzn/limestone-experiment-portal-types';
import { downloadFile, FileFormat } from '../../utils/file-utils';
import { ProductSelectionFile } from '../../form/attributes';
import { DisplayMode, IButtonHandler, LimestoneExperimentSelection } from '@amzn/limestone-experiment-portal-types';
import { CustomPieChart } from '../../common/CustomPieChart';

export interface ExperimentSelectionSectionState {
    s3FileUri: string;
    isPEASelectionComplete: boolean;
    showUploadFileBrowser: boolean;
    offersDownloadedFromExperimentService: boolean;
    downloadSelectionButtonLoading: boolean;
    editSelectionButtonDisabled: boolean;
    confirmEditSelectionButtonDisabled: boolean;
    confirmEditSelectionButtonLoading: boolean;
    downloadPEASelectionButtonLoading: boolean;
    downloadSelectionButtonDisabled: boolean;
    finalizeSelectionButtonDisabled: boolean;
    finalizeSelectionButtonLoading: boolean;
    showFinalizeSelectionModal: boolean;
    experimentOffers: ExperimentOfferDto[];
    updatedProductSelection: LimestoneExperimentSelection;
    peaSelectionWarningMessages: ListDisplayItem[];
    glMap: GlMap;
}

export interface ExperimentSelectionSectionProps extends IProps {
    realm: Realm;
    experimentId: string;
    marketplaceId: string;
    experimentStatus: ExperimentStatusType;
    setNotification: Function;
    userAccessLevels: Set<UserAccessLevel>;
    pagePermissionsMap: PermissionsMap;
    selectionFinalized?: boolean;
    experimentLifecycleType: LifecycleType;
}

export class ExperimentSelectionSection extends Component<ExperimentSelectionSectionProps, ExperimentSelectionSectionState> {
    public lemsApiHandler: LemsApiHandler;
    public awsServicesHandler: AwsServicesApiHandler;
    private readonly finalizeSelectionModalHandlers: IButtonHandler;
    private readonly buttonHandlers: any;

    constructor(props: ExperimentSelectionSectionProps) {
        super(props);
        this.state = {
            s3FileUri: '',
            experimentOffers: [],
            isPEASelectionComplete: false,
            showUploadFileBrowser: false,
            offersDownloadedFromExperimentService: false,
            downloadPEASelectionButtonLoading: false,
            downloadSelectionButtonLoading: false,
            downloadSelectionButtonDisabled: true,
            editSelectionButtonDisabled: true,
            confirmEditSelectionButtonDisabled: true,
            confirmEditSelectionButtonLoading: false,
            finalizeSelectionButtonDisabled: true,
            finalizeSelectionButtonLoading: false,
            showFinalizeSelectionModal: false,
            updatedProductSelection: {
                offersFile: new DisplayAttribute(AttributeLabels.OFFERS_FILE)
            },
            peaSelectionWarningMessages: [],
            glMap: {},
        };

        this.lemsApiHandler = new LemsApiHandlerImpl(props.realm);
        this.awsServicesHandler = new AwsServicesApiHandlerImpl(props.realm);

        this.buttonHandlers = {
            downloadSelection: () => this.downloadSelection(),
            editSelection: () => this.setState({ showUploadFileBrowser: true }),
            confirmEditSelection: () => this.confirmEditSelection(),
            finalizeSelection: (event: any) => {
                event.stopPropagation();
                this.setState({ showFinalizeSelectionModal: true });
            }
        };

        this.finalizeSelectionModalHandlers = {
            dismiss: () => this.setState({ showFinalizeSelectionModal: false }),
            submit: () => this.finalizeSelection()
        };
    }

    componentDidMount = async() => {
        await this.lemsApiHandler.getPreExperimentSelectionAnalysisResult(this.props.experimentId)
            .then((response: PreExperimentSelectionAnalysisResultDto|undefined) => {
                if (response) {
                    const peaSelectionWarnings: ListDisplayItem[] = Object.entries(response.preExperimentSelectionAnalysisWarnings)
                        .filter((warning: [string, SelectionAnalysisWarningDto]) => warning[1].warningType !== SelectionAnalysisWarningType.NONE)
                        .map((warning: [string, SelectionAnalysisWarningDto]) => {
                            return {
                                id: warning[0],
                                primaryText: warning[1].warningMessage
                            };
                        });

                    const filteredGlMap: GlMap = {};
                    Object.keys(response.glMap).forEach((glName: string) => {
                        if (response.glMap[glName] > 0.05) {
                            filteredGlMap[glName] = response.glMap[glName];
                        }
                    });

                    this.setState({
                        peaSelectionWarningMessages: peaSelectionWarnings,
                        s3FileUri: response.selectionDataS3Uri,
                        isPEASelectionComplete: true,
                        glMap: filteredGlMap
                    });
                }
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification, NOTIFICATION_MESSAGES.getPEASelectionResult.FAIL!));

        await this.getExperimentOffers();
    }

    getExperimentOffers = async() => {
        await this.lemsApiHandler.getAllOffersInExperiment(this.props.experimentId, this.props.marketplaceId)
            .then((response) => {
                this.setState({ experimentOffers: response, downloadSelectionButtonDisabled: false, offersDownloadedFromExperimentService: true });

                if (this.props.experimentStatus === ExperimentStatusType.VALIDATION_IN_PROGRESS) {
                    const validationPassedOffers = response.filter((offer) => offer.offerValidationStatus === OfferValidationStatusType.PASS).length;
                    const validationFailedOffers = response.filter((offer) => offer.offerValidationStatus === OfferValidationStatusType.FAIL || offer.offerValidationStatus === OfferValidationStatusType.INCONCLUSIVE).length;

                    this.props.setNotification!(NOTIFICATION_MESSAGES.validationInProgress, (
                        <ProgressBar
                            data-test-id='validation-flashbar'
                            value={((validationFailedOffers + validationPassedOffers) / response.length) * 100}
                            variant='flash'
                        />
                    ));
                }

                if (this.props.experimentStatus === ExperimentStatusType.STARTING_EXPERIMENT) {
                    const regionalizedOffers = response.filter((offer) => offer.offerRegionalizationStatus === OfferRegionalizationStatusType.REGIONALIZED).length;

                    this.props.setNotification!(NOTIFICATION_MESSAGES.startingExperiment, (
                        <ProgressBar
                            data-test-id='regionalization-flashbar'
                            value={(regionalizedOffers / response.length) * 100}
                            variant='flash'
                        />
                    ));
                }

                if (this.props.experimentStatus === ExperimentStatusType.ENDING_EXPERIMENT) {
                    const unRegionalizedOffers = response.filter((offer) => offer.offerRegionalizationStatus === OfferRegionalizationStatusType.NOT_REGIONALIZED).length;

                    this.props.setNotification!(NOTIFICATION_MESSAGES.endingExperiment, (
                        <ProgressBar
                            data-test-id='unregionalization-flashbar'
                            value={(unRegionalizedOffers / response.length) * 100}
                            variant='flash'
                        />
                    ));
                }

                if (this.props.experimentStatus === ExperimentStatusType.VALIDATION_COMPLETE && this.props.selectionFinalized === false) {
                    this.setState({ finalizeSelectionButtonDisabled: false, editSelectionButtonDisabled: false });
                    const actionRequiredMessage = response.every((offer) => offer.offerValidationStatus === OfferValidationStatusType.PASS)
                        ? getActionRequiredNotification('CONFIRM_SELECTION_PASS', ActionRequiredMessages.CONFIRM_SELECTION_PASS)
                        : getActionRequiredNotification('CONFIRM_SELECTION_WARNINGS', ActionRequiredMessages.CONFIRM_SELECTION_WARNINGS);

                    this.props.setNotification!(actionRequiredMessage);
                }
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification, NOTIFICATION_MESSAGES.downloadSelection.FAIL!));
    }

    downloadSelectionAnalysis = async() => {
        this.setState({ downloadPEASelectionButtonLoading: true });

        this.awsServicesHandler.downloadSelectionAnalysisFile(this.props.experimentId, this.state.s3FileUri)
            .then(() =>  this.props.setNotification!(NOTIFICATION_MESSAGES.downloadPEASelection.SUCCESS))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.downloadPEASelection.FAIL!))
            .finally(() => this.setState({ downloadPEASelectionButtonLoading: false }));
    }

    downloadSelection = async(): Promise<void> => {
        const rows = ['ASIN,Regionalization Status,Validation Status,Validation Failed Reasons'];
        this.state.experimentOffers.forEach((offer: ExperimentOfferDto) => {
            const offerValidationFailedReasons = offer.offerValidationFailedReasons ? offer.offerValidationFailedReasons.join(' and ') : '';
            rows.push(`${offer.asin},${offer.offerRegionalizationStatus},${offer.offerValidationStatus},${offerValidationFailedReasons}`);
        });

        downloadFile(rows, `${this.props.experimentId!}-product-selection`, FileFormat.CSV);
    }

    confirmEditSelection = async(): Promise<void> => {
        this.setState({ confirmEditSelectionButtonLoading: true });

        this.lemsApiHandler.uploadProductSelection(this.state.updatedProductSelection.offersFile.payloadValue, this.props.experimentId, this.props.marketplaceId, BaseEntityType.EXPERIMENT)
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.uploadProductSelection.FAIL!))
            .finally(() => this.setState({ confirmEditSelectionButtonLoading: false, showUploadFileBrowser: false }));
    }

    finalizeSelection = async() => {
        this.setState({ finalizeSelectionButtonLoading: true, showFinalizeSelectionModal: false });

        await this.lemsApiHandler.finalizeProductSelection(this.props.experimentId)
            .then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.finalizeSelection.SUCCESS);
                this.setState({ finalizeSelectionButtonDisabled: true });
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.finalizeSelection.FAIL))
            .finally(() => this.setState({ finalizeSelectionButtonLoading: false }));
    }

    updateFormState = (fieldId: string, payloadValue: any, displayValue: string, isValid: boolean): void => {
        const updatedProductSelection = this.state.updatedProductSelection;
        updatedProductSelection[fieldId].updateAttributeDetails(isValid, payloadValue, displayValue);
        this.setState({ updatedProductSelection, confirmEditSelectionButtonDisabled: !isValid });
    }

    render() {
        const selectionAnalysisTestId = this.state.peaSelectionWarningMessages.length ? 'pea-selection-result-with-warnings' : 'pea-selection-result-without-warnings';
        const selectionAnalysisSection = (
            <PermissionControlledView
                hideIfNotAuthorized={true}
                actionType={ActionType.ELEVATED_READ}
                userAccessLevels={this.props.userAccessLevels}
                pagePermissionsMap={this.props.pagePermissionsMap}
            >
                <Accordion data-testid={selectionAnalysisTestId} defaultExpanded={false} style={{ marginTop: '5px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                        <Typography variant='body1' style={{ marginLeft: '10px' }}>Selection Analysis Warnings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListDisplay items={this.state.peaSelectionWarningMessages} />
                    </AccordionDetails>
                </Accordion>
            </PermissionControlledView>
        );

        let selectionChartDisplay: JSX.Element|null;
        if (this.props.experimentLifecycleType === LifecycleType.RESULT_ANALYSIS_ONLY) {
            selectionChartDisplay = null;
        } else if (this.props.experimentStatus === ExperimentStatusType.VALIDATION_COMPLETE) {
            const validationPassedOffers = this.state.experimentOffers.filter((offer) => offer.offerValidationStatus === OfferValidationStatusType.PASS).length;
            selectionChartDisplay = (
                <>
                    <Box variant='h5' padding='n' textAlign='center'>Below is a summary of selection validation (Total: {this.state.experimentOffers.length.toLocaleString()})</Box>
                    <CustomPieChart
                        data-testid='validation-breakdown-chart'
                        data={[
                            {
                                'title': 'Passed Offers',
                                'value': validationPassedOffers,
                            },
                            {
                                'title': 'Failed Offers',
                                'value': this.state.experimentOffers.length - validationPassedOffers,
                            }
                        ]}
                    />
                </>
            );
        } else if (!_.isEmpty(this.state.glMap)) {
            selectionChartDisplay = (
                <>
                    <Box variant='h5' padding='n' textAlign='center'>Breakdown of top GL categories in the experiment selection (Total: {this.state.experimentOffers.length.toLocaleString()})</Box>
                    <CustomPieChart
                        data-testid='selection-breakdown-chart'
                        data={Object.keys(this.state.glMap).map((glName: string) => {
                            return {
                                title: glName,
                                value: this.state.glMap[glName]
                            };
                        })}
                        isPercentage
                    />
                </>

            );
        } else {
            const regionalizedOffers = this.state.experimentOffers.filter((offer) => offer.offerRegionalizationStatus === OfferRegionalizationStatusType.REGIONALIZED).length;
            selectionChartDisplay = (
                <>
                    <Box variant='h5' padding='n' textAlign='center'>Detailed Selection Breakdown will be available when pre-experiment selection analysis is performed.</Box>
                    <CustomPieChart
                        data-testid='vanilla-selection-chart'
                        data={[
                            {
                                'title': 'Offers in Regionalized State',
                                'value': regionalizedOffers,
                            },
                            {
                                'title': 'Offers in Unregionalized State',
                                'value': this.state.experimentOffers.length - regionalizedOffers,
                            }
                        ]}
                    />
                </>
            );
        }

        return (
            <div style={{ paddingBottom: 20 }}>
                <UserInputModal
                    visible={this.state.showFinalizeSelectionModal}
                    buttonHandlers={this.finalizeSelectionModalHandlers}
                    {...FinalizeSelectionModalAttributes}
                />
                <ExpandableSection
                    variant='container'
                    defaultExpanded={true}
                    headerText={'Experiment Selection'} 
                    headerActions={
                        <SpaceBetween size={'s'} direction={'horizontal'}>
                            {this.state.isPEASelectionComplete && <PermissionControlledButton
                                testId={'download-pea-selection-button'}
                                userAccessLevels={this.props.userAccessLevels}
                                actionType={ActionType.WRITE}
                                pagePermissionsMap={this.props.pagePermissionsMap}
                                hideIfNotAuthorized={true}
                                buttonProps={{
                                    loading: this.state.downloadPEASelectionButtonLoading,
                                    disabled: !this.state.isPEASelectionComplete,
                                    onClick: this.downloadSelectionAnalysis,
                                    variant: 'primary'
                                }}
                            >Pre Experiment ASIN Analysis</PermissionControlledButton>}
                            {<PermissionControlledButton
                                testId={'finalize-selection-button'}
                                userAccessLevels={this.props.userAccessLevels}
                                actionType={ActionType.WRITE}
                                pagePermissionsMap={this.props.pagePermissionsMap}
                                hideIfNotAuthorized={true}
                                buttonProps={{
                                    loading: this.state.finalizeSelectionButtonLoading,
                                    disabled: this.state.finalizeSelectionButtonDisabled,
                                    onClick: this.buttonHandlers.finalizeSelection,
                                    variant: 'primary'
                                }}>Finalize Selection</PermissionControlledButton>}
                        </SpaceBetween>
                    }>

                    {!this.state.offersDownloadedFromExperimentService ? (<Spinner size={'large'} />) : (
                        <>
                            <div data-testid={'selection-summary'}>
                                {selectionChartDisplay}
                            </div>
                            <Box display={'inline-block'}>
                                <SpaceBetween size={'s'} direction={'horizontal'}>
                                    <PermissionControlledButton
                                        testId={'download-selection-button'}
                                        userAccessLevels={this.props.userAccessLevels}
                                        actionType={ActionType.SAVE}
                                        pagePermissionsMap={this.props.pagePermissionsMap}
                                        hideIfNotAuthorized={false}
                                        buttonProps={{
                                            loading: this.state.downloadSelectionButtonLoading,
                                            onClick: this.buttonHandlers.downloadSelection,
                                        }}
                                    >Download Selection</PermissionControlledButton>

                                    <PermissionControlledButton
                                        testId={'edit-selection-button'}
                                        userAccessLevels={this.props.userAccessLevels}
                                        actionType={ActionType.WRITE}
                                        pagePermissionsMap={this.props.pagePermissionsMap}
                                        hideIfNotAuthorized={true}
                                        buttonProps={{
                                            disabled: this.state.editSelectionButtonDisabled,
                                            onClick: this.buttonHandlers.editSelection,
                                        }}
                                    >Edit Selection</PermissionControlledButton>
                                </SpaceBetween>
                            </Box>
                            <IconButton size="medium" onClick={this.getExperimentOffers}><RefreshIcon/></IconButton>
                            {this.state.showUploadFileBrowser && (
                                <>
                                    <ProductSelectionFile
                                        updateFormState={this.updateFormState}
                                        displayMode={DisplayMode.CREATE}
                                    />
                                    <PermissionControlledButton
                                        testId={'submit-edit-selection-button'}
                                        userAccessLevels={this.props.userAccessLevels}
                                        actionType={ActionType.WRITE}
                                        pagePermissionsMap={this.props.pagePermissionsMap}
                                        hideIfNotAuthorized={false}
                                        buttonProps={{
                                            loading: this.state.confirmEditSelectionButtonLoading,
                                            disabled: this.state.confirmEditSelectionButtonDisabled,
                                            onClick: this.buttonHandlers.confirmEditSelection,
                                        }}
                                    >Submit Edited Selection</PermissionControlledButton>
                                </>

                            )}
                        </>
                    )}
                    {this.state.isPEASelectionComplete && selectionAnalysisSection}
                </ExpandableSection>
            </div>
        );
    }
}
